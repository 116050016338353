var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("navbar.homepage")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-6 mt-4 pt-2"
  }, [_c('div', {
    staticClass: "media key-feature align-items-center p-3 rounded shadow"
  }, [_c('div', {
    staticClass: "icon text-center rounded-circle mr-3"
  }, [_c('monitor-icon', {
    staticClass: "fea icon-ex-md text-primary"
  })], 1), _vm._m(0)])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 mt-4 pt-2"
  }, [_c('div', {
    staticClass: "media key-feature align-items-center p-3 rounded shadow"
  }, [_c('div', {
    staticClass: "icon text-center rounded-circle mr-3"
  }, [_c('heart-icon', {
    staticClass: "fea icon-ex-md text-primary"
  })], 1), _vm._m(1)])]), _c('div', {
    staticClass: "col-lg-4 col-md-6 mt-4 pt-2"
  }, [_c('div', {
    staticClass: "media key-feature align-items-center p-3 rounded shadow"
  }, [_c('div', {
    staticClass: "icon text-center rounded-circle mr-3"
  }, [_c('eye-icon', {
    staticClass: "fea icon-ex-md text-primary"
  })], 1), _vm._m(2)])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "media-body"
  }, [_c('h4', {
    staticClass: "title mb-0"
  }, [_vm._v("3 Pending Wasiat")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "media-body"
  }, [_c('h4', {
    staticClass: "title mb-0"
  }, [_vm._v("3 Clients")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "media-body"
  }, [_c('h4', {
    staticClass: "title mb-0"
  }, [_vm._v("4 Pending Approval")])]);
}]

export { render, staticRenderFns }